import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { IScopedSession } from '../../../tracing/session';
import { notification } from 'antd';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { ICreateUserRequest } from '../../Models/Requests/UserAdmin/ICreateUserRequest';
import { IUpdateUserRequest } from '../../Models/Requests/UserAdmin/IUpdateUserRequest';
import { IAccessControlProjection } from '../../Models/Responses/UserAdmin/IAccessControlProjection';
import { ICampaignProjection } from '../../Models/Responses/UserAdmin/ICampaignProjection';
import { IOrganizationProjection } from '../../Models/Responses/UserAdmin/IOrganizationProjection';
import { IRoleProjection } from '../../Models/Responses/UserAdmin/IRoleProjection';
import { IUserProjection } from '../../Models/Responses/UserAdmin/IUserProjection';
import { IExistingEmailSearchResult } from './IUserAdminSlice';
import { stringForError } from '../../Utility/Utils';

/**
 * Inner method to just fetch data from our useradmin controller
 * @param path The API to get
 * @param getState ThunkAPI GetState() call
 * @param fulfillWithValue ThunkAPI fulfill call
 * @param rejectWithValue ThunkAPI reject call
 * @param context The context (See IScopedSession) to trace calls
 * @returns 
 */
export async function simpleGet<T>(
	path: string,
	getState: () => RootState,
	fulfillWithValue,
	rejectWithValue,
	context: IScopedSession) 
{
	let errorMessage = '';
	try 
	{
		const api = new Api('/api/5', getState().auth.userAuthToken, context);
		const response = await api.getAsync<T>(path);

		// If we got a successful response we return it
		if (response.ErrorCode === null && response.Success) 
		{
			return fulfillWithValue(response.Data);
		}

		// Get the error from the API response
		errorMessage = response.ErrorMessage;
	}
	// Likely a NetError thrown from the Api class
	catch (e) 
	{
		errorMessage = e.message;
	}

	// If we got this far there was an error of one kind of another
	return rejectWithValue(errorMessage);
}

/**
 * Fetch the users list
 */
export const getUsers = createTracedAsyncThunk<IUserProjection[], void, { dispatch: AppDispatch, state: RootState }>(
	'useradmin/users/get',
	async (context, request, {getState, rejectWithValue, fulfillWithValue}) =>
	{
		return await simpleGet<IUserProjection[]>('useradmin/users', getState, fulfillWithValue, rejectWithValue, context);
	}
);

/**
 * Fetch the organizations list
 */
export const getOrganizations = createTracedAsyncThunk<IOrganizationProjection[], void, { dispatch: AppDispatch, state: RootState }>(
	'useradmin/organizations/get',
	async (context, request, {getState, rejectWithValue, fulfillWithValue}) =>
	{
		return await simpleGet<IOrganizationProjection[]>('useradmin/organizations', getState, fulfillWithValue, rejectWithValue, context);
	}
);

/**
 * Fetch the roles list
 */
export const getRoles = createTracedAsyncThunk<IRoleProjection[], void, { dispatch: AppDispatch, state: RootState }>(
	'useradmin/roles/get',
	async (context, request, {getState, rejectWithValue, fulfillWithValue}) =>
	{
		return await simpleGet<IRoleProjection[]>('useradmin/roles', getState, fulfillWithValue, rejectWithValue, context);
	}
);

/**
 * Fetch the access controls list
 */
export const getAccessControls = createTracedAsyncThunk<IAccessControlProjection[], void, { dispatch: AppDispatch, state: RootState }>(
	'useradmin/accesscontrol/get',
	async (context, request, {getState, rejectWithValue, fulfillWithValue}) =>
	{
		return await simpleGet<IAccessControlProjection[]>('useradmin/accesscontrol', getState, fulfillWithValue, rejectWithValue, context);
	}
);

/**
 * Fetch the campaigns list
 */
export const getCampaigns = createTracedAsyncThunk<ICampaignProjection[], void, { dispatch: AppDispatch, state: RootState }>(
	'useradmin/campaigns/get',
	async (context, request, {getState, rejectWithValue, fulfillWithValue}) =>
	{
		return await simpleGet<ICampaignProjection[]>('campaigns', getState, fulfillWithValue, rejectWithValue, context);
	}
);

/**
 * Save modifications made to a user; notify with the result.
 */
export const saveUser = createTracedAsyncThunk<IUserProjection, {id:string, update:IUpdateUserRequest}, { dispatch: AppDispatch, state: RootState }>(
	'useradmin/user/update',
	async (context, {id, update}, {getState, rejectWithValue, fulfillWithValue}) => 
	{
		try
		{
			const api = new Api('/api/5', getState().auth.userAuthToken, context);
			const response = await api.patchAsync<IUserProjection>(`useradmin/users/${id}`, update);
			notification.success({
				message: 'Success',
				description: 'The user has been updated successfully',
				placement: 'bottomRight'
			});
			return fulfillWithValue(response.Data);
		}
		catch(err)
		{
			notification.error({
				message: 'Error',
				description: stringForError(err),
				placement: 'bottomRight'
			});
			return rejectWithValue(err);
		}
	}
);

/**
 * Perform a search of an email address against the local user list and the cropwise account search feature.
 */
export const searchForEmail = createTracedAsyncThunk<IExistingEmailSearchResult, string, { dispatch: AppDispatch, state: RootState }>(
	'useradmin/email/search',
	async (context, email, {getState, rejectWithValue, fulfillWithValue}) => 
	{
		const users = getState().userAdmin.users;
		try
		{
			// First just see if we already know the email has been assigned to a user
			const existingUser = users.find(u => u.Username.toLowerCase() == email.toLowerCase());
			if(existingUser)
			{
				const result = {
					email,
					existingUser,
					success: false
				};
				return fulfillWithValue(result);
			}

			// If not, then execute the cropwise lookup so we can let the user know if an account exists there.
			const api = new Api('/api/5', getState().auth.userAuthToken, context);
			const response = await api.getAsync<string|undefined>(`useradmin/cropwise/${encodeURIComponent(email)}`);
			const result = {
				email,
				success: true, 
				error: undefined,
				cropwise: response.Data
			};
			return fulfillWithValue(result);
		}
		catch(err)
		{
			return rejectWithValue({
				email,
				success: !users.some(u => u.Username.toLowerCase() == email.toLowerCase()), 
				error: stringForError(err),
				cropwise: undefined
			});
		}
	}
);

/**
 * Wrapper for saveUser that just changes the activation state of the user
 */
export const toggleActivation = (u: IUserProjection) => 
{
	return saveUser({id: u.Id, update: {
		IsDeactivated: (!u.IsDeactivated)
	} });
};

/**
 * Update which user is currently being edited
 */
export const setUserIdEdit = (state: RootState, id: string) =>
	state.userAdmin.editingUserId = id;

/**
 * Create a brand new user.
 */
export const createUser = createTracedAsyncThunk<IUserProjection, ICreateUserRequest, { dispatch: AppDispatch, state: RootState }>(
	'useradmin/user/create',
	async (context, data, {getState, rejectWithValue, fulfillWithValue}) => 
	{
		try
		{
			const api = new Api('/api/5', getState().auth.userAuthToken, context);
			const response = await api.postAsync<IUserProjection>('useradmin/users', data);
			notification.success({
				message: 'Success',
				description: 'The user has been created',
				placement: 'bottomRight'
			});
			return fulfillWithValue(response.Data);
		}
		catch(err)
		{
			notification.error({
				message: 'Error',
				description: stringForError(err),
				placement: 'bottomRight'
			});
			return rejectWithValue(err);
		}
	}
);

/**
 * Send an invitation with a specific campaign to a user
 */
export const sendInvitationToUser = createTracedAsyncThunk<boolean, { campaign: ICampaignProjection, user: IUserProjection, }, { dispatch: AppDispatch, state: RootState }>(
	'useradmin/invitation/send',
	async (context, {campaign,user}, {getState, rejectWithValue, fulfillWithValue}) => 
	{
		try
		{
			const api = new Api('/api/5', getState().auth.userAuthToken, context);
			const response = await api.postAsync<ICampaignProjection[]>(`campaigns/${campaign.Id}/users/${user.Id}/invitation/email`, {});
			if(response.Data)
			{	
				notification.success({
					message: 'Invitation Sent',
					description: `${user.Name} was sent an invitation email at ${user.Username} with the subject '${campaign?.Subject}'`,
					placement: 'bottomRight'
				});
				return fulfillWithValue(true);
			}
			notification.info({
				message: 'No invitation sent',
				description: `${user.Name} did not receive an invitation email as they have previously logged in.`,
				placement: 'bottomRight'
			});
			return fulfillWithValue(false);

		}
		catch(err)
		{
			notification.error({
				message: 'Error',
				description: stringForError(err),
				placement: 'bottomRight'
			});
			return rejectWithValue(err);
		}
	}
);
