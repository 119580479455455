import React, { useCallback, useEffect } from 'react';
import { Input, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useMemo, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { ReactComponent as CircledCheckGreen } from '../../assets/images/CircledCheckGreen.svg';
import { ReactComponent as ConflictIcon } from '../../assets/images/Conflict.svg';
import { ReactComponent as PencilIcon } from '../../assets/images/Pencil.svg';
import { ReactComponent as XIcon } from '../../assets/images/Xmark.svg';
import { IconButton } from '../../components/IconButton/IconButton';
import { StyledModal } from '../../components/StyledModal/StyledModal';
import { useTheme } from 'styled-components';
import { EditUserForm } from './Components/EditUserForm';
import { CreateUserForm } from './Components/CreateUserForm';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../logic/store/Store';
import { IUserProjection } from '../../logic/Models/Responses/UserAdmin/IUserProjection';
import { IUpdateUserRequest } from '../../logic/Models/Requests/UserAdmin/IUpdateUserRequest';
import { ICreateUserRequest } from '../../logic/Models/Requests/UserAdmin/ICreateUserRequest';
import { stableStringSort } from '../../logic/Utility/Utils';
import { selectIsLoading, selectEditingUser, selectDistinctCompanies, selectDistinctLevels, selectDistinctDomains, accessControlLabel, accessControlsForUser } from '../../logic/store/UserAdmin/UserAdminSelectors';
import { setEditingUserId } from '../../logic/store/UserAdmin/UserAdminSlice';
import { saveUser, createUser, toggleActivation, getUsers, getRoles, getOrganizations, getAccessControls, getCampaigns } from '../../logic/store/UserAdmin/UserAdminThunks';

/**
 * The page level component for User Admin.
 * 
 * Manages the User listing directly, as well as a the create and edit user modals.
 * In the future will allow editing campaigns and roles.
 */
export const UserAdminPage = () => 
{
	const theme = useTheme();
	const dispatch = useDispatch();
	
	// TODO load permissions once we can view/edit roles

	const isLoading = useSelector(selectIsLoading);
	const loadingCount = useSelector((state: RootState) => state.userAdmin.loadingCount);

	const users = useSelector((state: RootState) => state.userAdmin.users);

	const roles = useSelector((state: RootState) => state.userAdmin.roles);
	const organizations = useSelector((state: RootState) => state.userAdmin.organizations);
	const accessControls = useSelector((state: RootState) => state.userAdmin.accessControls);
	const campaigns = useSelector((state: RootState) => state.userAdmin.campaigns);

	const userEdit = useSelector(selectEditingUser);
	const distinctCompanies = useSelector(selectDistinctCompanies);
	const distinctLevels = useSelector(selectDistinctLevels);
	const distinctDomains = useSelector(selectDistinctDomains);

	const [showCreateUser, setShowCreateUser] = useState(false);
	
	const [searchNeedle, setSearchNeedle] = useState<string>();
	// If the user has typed in a filter, apply it to the list of users
	const filteredUsers = useMemo(() => 
	{
		if(!searchNeedle?.length)
		{
			return users;
		}

		const needle = searchNeedle.toLowerCase();
		return users.filter(u =>
			u.AccessControlOrganizationalUnit?.toLowerCase().includes(needle)
			|| u.Company?.toLowerCase().includes(needle)
			|| u.FoundationId?.toLowerCase().includes(needle)
			|| u.Name?.toLowerCase().includes(needle)
			|| u.Notes?.toLowerCase().includes(needle)
			|| u.RoleName.toLowerCase().includes(needle)
			|| u.Username.toLowerCase().includes(needle)
			|| accessControlLabel(accessControlsForUser(accessControls, u)).toLowerCase().includes(needle)
		);
	}, [searchNeedle, users, accessControls]);

	const setUserIdEditAction = useCallback((id: string | undefined) => 
	{
		dispatch(setEditingUserId(id));
	}, [dispatch]);

	const saveUserAction = useCallback((params: {id: string, update: IUpdateUserRequest}) => 
	{
		dispatch(saveUser(params));
	},[dispatch]);
	
	const createUserAction = useCallback((data: ICreateUserRequest) => 
	{
		dispatch(createUser(data));
	}, [dispatch]);

	const toggleActivationAction = useCallback((user: IUserProjection) => 
	{
		dispatch(toggleActivation(user));
	}, [dispatch]);

	// Initially fetch all the lists of data
	useEffect(() => 
	{
		dispatch(getUsers());
		dispatch(getRoles());
		dispatch(getOrganizations());
		dispatch(getAccessControls());
		dispatch(getCampaigns());
	}, [dispatch]);
	
	// Build the columns for the table
	const columns = useMemo<ColumnsType<IUserProjection>>(() => [
		{
			title: 'Name',
			dataIndex: '',
			sorter: (a, b) => stableStringSort(a.Name, b.Name),
			sortDirections: ['descend', 'ascend'],
			width: '20%',
			filters: distinctCompanies.map<ColumnFilterItem>(c => ({
				text: c,
				value: c
			})),
			onFilter: (value: string, record) => record.Company === value,
			render: function NameCell(a, record, idx) 
			{ 
				return <div style={{display:'flex', flexDirection:'column'}}>
					<h4>{record.Name}</h4>
					<h5>{record.Company}</h5>
				</div>; 
			}
		},
		{
			title: 'Email',
			dataIndex: 'Username',
			sorter: (a, b) => stableStringSort(a.Username, b.Username),
			sortDirections: ['descend', 'ascend'],
			width: '20%',
			filters: distinctDomains.map<ColumnFilterItem>(c => ({
				text: c,
				value: c
			})),
			onFilter: (value: string, record) => record.Username.toLowerCase().endsWith(value.toLowerCase())
		},
		{
			title: 'Foundation ID',
			dataIndex: 'FoundationId',
			sorter: (a, b) => stableStringSort(a.FoundationId, b.FoundationId),
			sortDirections: ['descend', 'ascend'],
			width: '10%'
		},
		{
			title: 'Role',
			dataIndex: 'RoleName',
			sorter: (a, b) => stableStringSort(a.RoleName, b.RoleName),
			sortDirections: ['descend', 'ascend'],
			width: '20%',
			filters: roles.map<ColumnFilterItem>(r => ({
				text: r.Name,
				value: r.Id
			})),
			onFilter:  (value: string, record) => record.RoleId === value
		},
		{
			title: 'Level',
			dataIndex: '',
			sorter: (a, b) => stableStringSort(accessControlLabel(accessControlsForUser(accessControls, a)), accessControlLabel(accessControlsForUser(accessControls, b))),
			sortDirections: ['descend', 'ascend'],
			width: '20%',
			filters: distinctLevels.map<ColumnFilterItem>(r => ({
				text: r,
				value: r
			})),
			onFilter:  (value: string, record) => accessControlLabel(accessControlsForUser(accessControls, record)).toLowerCase().includes(value.toLowerCase()),
			render: function LevelCell(a, record, idx) 
			{
				const acs = accessControlsForUser(accessControls, record);
				return <div>{acs.length > 1 ? <Tooltip title='Multiple levels found for this email'><ConflictIcon /></Tooltip> : ''} {accessControlLabel(acs)}</div>;
			}
		},
		{
			title: 'Last Login',
			dataIndex: 'LastLoginSuccess',
			sorter: (a, b) => stableStringSort(a.LastLoginSuccess, b.LastLoginSuccess),
			sortDirections: ['descend', 'ascend'],
			width: '10%',
			render: function DateCell(a, record, idx) 
			{
				return record.LastLoginSuccess ? new Date(record.LastLoginSuccess).toLocaleString() : ''; 
			}
		},
		{
			title: 'Active',
			dataIndex: '',
			render: function CommandCell(a, record, idx) 
			{ 
				return <div style={{display:'flex', flexDirection:'row'}}>
					<IconButton onClick={() => setUserIdEditAction(record.Id)}>
						<PencilIcon />
					</IconButton>
					<IconButton onClick={() => setUserIdEditAction(record.Id)}>
						{!record.IsDeactivated 
							? <CircledCheckGreen /> 
							: <XIcon />}
					</IconButton>
				</div>;
			},
			width: '10%',
			filters: [
				{
					text: 'Active',
					value: true
				},
				{
					text: 'Inactive',
					value: false
				}
			],
			onFilter:  (value, record) => Boolean((value === undefined || value === null || 
				(!value && !!record.IsDeactivated) || (value && !record.IsDeactivated)))
		}
	], [distinctCompanies, distinctDomains, roles, distinctLevels, accessControls, setUserIdEditAction]);

	const onCancel = useCallback(() => 
	{
		setUserIdEditAction(undefined);
		setShowCreateUser(false);
	}, [setUserIdEditAction]);

	const switchToUser = useCallback((id: string) => 
	{
		setUserIdEditAction(id);
		setShowCreateUser(false);
	}, [setUserIdEditAction]);

	return <div style={{ position: 'relative', top: 0, left: 0, right: 0, bottom: 0, overflow: 'clip', height: '100%', display: 'flex', flexDirection:'column', alignItems: 'stretch', justifyContent: 'flex-start'}}>
		<h1 style={{padding: 8, paddingTop: 16, margin: 0}}>User Admin</h1>
		<div style={{ display: 'flex', flexDirection: 'row', padding: 8 }}>
			{/* Placeholder for the tab UI once we have multiple admin panels */}
			<Button style={{width:200, flexGrow:1}} variant='dark'>Users</Button>
			<Button style={{width:200, flexGrow:1}} variant='outlined'>Registration</Button>
			<Button style={{width:200, flexGrow:1}} variant='outlined'>Roles</Button>
		</div>
		<div style={{padding: 8, display: 'flex', flexDirection:'row', alignItems: 'stretch'}}>
			<div style={{display:'flex', flexDirection:'column', flexGrow: 1}}>
				{/* Our search here will filter the user list in the table */}
				<h3>Search:</h3>
				<Input.Search
					enterButton
					defaultValue={''} 
					loading={isLoading}
					onSearch={setSearchNeedle}
				/>
			</div>
			{/* Let the user create a new user via the modal below */}
			<Button variant='dark' onClick={() => setShowCreateUser(true)} style={{marginLeft: 16, height:'100%', minWidth: 100}}>Add User</Button>
		</div>
		<div style={{flexGrow: 1, overflow: 'auto'}}>
			{/* Our data table; could become 'virtual' in the future to avoid paging */}
			<Table sticky columns={columns} dataSource={filteredUsers} />
			
			{/* Display a loading spinner when the data is loading */ }
			<LoadingSpinner 
				loading={isLoading} 
				style={{position:'absolute', top:200, left: '50%', transform:'translateX(-50%)', zIndex:100000}}>
					Loading... ({loadingCount} remain)
			</LoadingSpinner>
		</div>

		{/* The modal for editing an existing user */}
		<StyledModal title={
			<div style={{
				fontWeight: theme.fontWeights.bold,
				fontFamily: theme.fonts.heading,
				fontSize: theme.fontSizes.xLarge,
				paddingTop: 10,
			}}>Edit User {userEdit?.Username} {userEdit?.IsDeactivated && `(Deactivated ${new Date(userEdit.IsDeactivated).toLocaleDateString()})`}</div>
		}
		open={!!userEdit}
		width={'85%'}
		closable={true}
		onCancel={onCancel}
		destroyOnClose={true}
		>
			{ !!userEdit && <EditUserForm 
				roles={roles} 
				campaigns={campaigns}
				organizations={organizations} 
				userEdit={userEdit} 
				accessControls={accessControlsForUser(accessControls, userEdit)}
				toggleActivation={toggleActivationAction}
				saveUser={saveUserAction} /> }
		</StyledModal>

		{/* The modal for creating a new user */}
		<StyledModal title={<div style={{
			fontWeight: theme.fontWeights.bold,
			fontFamily: theme.fonts.heading,
			fontSize: theme.fontSizes.xLarge,
			paddingTop: 10,
		}}>Create User</div>}
		open={showCreateUser}
		width={'85%'}
		closable={true}
		onCancel={onCancel}
		destroyOnClose={true}
		>
			<CreateUserForm 
				users={users} 
				accessControls={accessControls} 
				createUser={createUserAction} 
				switchToUser={switchToUser} 
				organizations={organizations} 
				roles={roles} />
		</StyledModal>
	</div>;
};
