import { ActionReducerMapBuilder, AsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserAdminSlice } from './IUserAdminSlice';
import { createUser, getAccessControls, getCampaigns, getOrganizations, getRoles, getUsers, saveUser, searchForEmail, sendInvitationToUser } from './UserAdminThunks';

// Our initial state is just empty
const initialState: IUserAdminSlice = {
	users: [],
	roles: [],
	organizations: [],
	accessControls: [],
	campaigns: [],
	loadingCount: 0,
	emailSearchResult: {}
};

export const userAdminSlice = createSlice({
	name: 'userInfo',
	initialState: initialState,
	reducers: {
		setEditingUserId: (state, action: PayloadAction<string | undefined>) => 
		{
			state.editingUserId = action.payload;
			return this;
		}
	},
	extraReducers: (builder) =>
	{
		bindLoadingCounter(builder, getUsers, (state, data) => 
		{
			state.users = data;
		});
		bindLoadingCounter(builder, saveUser, (state, data) => 
		{
			// replace the one that is updated
			state.users = state.users.map(p => p.Id === data.Id ? data : p);
		});
		bindLoadingCounter(builder, createUser, (state, data) => 
		{
			// replace the one that is updated
			state.users = [ data, ...state.users ];
			state.editingUserId = data.Id;
		});
		bindLoadingCounter(builder, getRoles, (state, data) => 
		{
			state.roles = data;
		});
		bindLoadingCounter(builder, getOrganizations, (state, data) => 
		{
			state.organizations = data;
		});
		bindLoadingCounter(builder, getAccessControls, (state, data) => 
		{
			state.accessControls = data;
		});
		bindLoadingCounter(builder, getCampaigns, (state, data) => 
		{
			state.campaigns = data;
		});
		bindLoadingCounter(builder, searchForEmail, (state, data) => 
		{
			state.emailSearchResult = data;
		});
		bindLoadingCounter(builder, sendInvitationToUser, (state, data) => {});
	}
});

/**
 * Simple wrapper to bind async data fetching to the loading counter
 */
function bindLoadingCounter<U,V,W> (builder: ActionReducerMapBuilder<IUserAdminSlice>, reducer: AsyncThunk<U, V, W>, apply: (slice: IUserAdminSlice, data: U) => unknown)
{
	builder.addCase(reducer.pending, (state) => { state.loadingCount++; } );
	builder.addCase(reducer.rejected, (state) => { state.loadingCount--; } );
	builder.addCase(reducer.fulfilled, (state, result) => 
	{ 
		apply(state, result.payload);
		state.loadingCount--;
	} );
}


export const { setEditingUserId } = userAdminSlice.actions;